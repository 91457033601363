import '../scss/style.scss';
import React from 'react';
import Head from '../components/Head';
import { Modal, TermsOfService } from 'carbonate-ui';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { navigateTo } from 'gatsby-link';
import $ from 'jquery'; // for Tealium

import config from '../config.js';
import loadTealium from '../utils/tealium';
import loadTypekit from '../utils/typekit';
import scrollTo from '../utils/scrollTo';

global.$ = $;

class Layout extends React.Component {
  constructor(props) {
    super();
    this.state = {
      privacyActive: false,
      termsActive: false,
    };
    this.handleFooterLogoClick = this.handleFooterLogoClick.bind(this);
    this.handleTermsClick = this.handleTermsClick.bind(this);
    this.handleTermsClose = this.handleTermsClose.bind(this);
    this.scriptLoaded = this.scriptLoaded.bind(this);
  }

  componentDidMount() {
    loadTealium();
    loadTypekit();

    const script = document.createElement("script");
    script.src = "https://tags.tiqcdn.com/utag/dpsg/tier2/prod/utag.js";
    script.async = true;
    document.body.appendChild(script);
  }
  handleFooterClick(text,link){
    var obj_text = text;
    var obj_link = link;
    if(obj_link == ''){
      obj_link = 'Pop up';
    }
    // utag.link({
    //   'event_name': 'click',
    //   'click_url': obj_link,
    //   'click_text': obj_text,
    //   'outbound_link_type': 'general link'
    //   });
  }

  scriptLoaded(e) {
    e.preventDefault();
    document.querySelector('.header').scrollIntoView({
      behavior: 'smooth'
    });
    window.utag?.gdpr?.showConsentPreferences();
    this.handleFooterClick('Do Not Share Or Sell My Personal Information','#');
  }

  handleNavClick(e, el,href) {
    var obj_text = e.target.innerText;
    var click_type = 'text';
    var obj_link = href;
    if(obj_link == null){
      click_type = 'icon';
    } 
    // utag.link({
    // 'event_name': 'internal_navigation',
    // 'nav_location': obj_link,
    // 'click_text': obj_text,
    // 'click_path': obj_link,
    // 'click_type': click_type 
    // });
    if(href == ''){
    e.preventDefault();
    scrollTo(el);
    }
  }

  handleFooterClickOutbound(e,text,href){
    var obj_text = text;
    var obj_link = href;
      //  utag.link({
      //    'event_name': 'click',
      //    'click_url': obj_link,
      //    'click_text': obj_text,
      //    'outbound_link_type': 'general link'
      // });
  }

  handleFooterLogoClick(e) {
    e.preventDefault();

    if (window.location.pathname === '/') {
      scrollTo();
    } else {
      navigateTo('/');
    }
  }

  handleTermsClick(e) {
    this.handleFooterClick('Terms of use','');
    if (!this.state.termsActive) e.preventDefault();
    this.setState({ termsActive: !this.state.termsActive });
  }

  handleTermsClose(e){
    if (!this.state.termsActive) e.preventDefault();
    this.setState({ termsActive: !this.state.termsActive });
  }

  render() {
    return (
      <div className={`layout${this.props.className ? ` ${this.props.className}` : ''}`}>
        <Head
          ogURL={this.props.href}
          {...this.props.head}
        />
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{ __html:
              `var utag_data = {
                GoogleAccount : "", // 
                page_name : "", // Contains a user-friendly names for the page.
                page_section : "", // Contains a user-friendly page section, e.g. 'configuration section'.
                page_category : "", // Contains a user-friendly page category, e.g. 'appliance page'.
                page_subcategory : "", // Contains a user-friendly page subcategory, e.g. 'refrigerator page'.
                page_type : "", // Contains a user-friendly page type, e.g. 'cart page'.
                ga_category : "", // 
                ga_action : "", // 
                ga_label : "", // 
                ga_page : "", // 
                ga_href : "", // 
                ga_title : "", // 
                search_term : "", // 
                ga_interaction_type : "", // 
                ga_pagelocation : "" // 
              }`
            }}
          />
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{ __html:
              `(function(a,b,c,d){
                a='//tags.tiqcdn.com/utag/dpsg/tier2/prod/utag.js';
                b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
                a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
              })();`
            }}
          />
          <script type= "text/javascript" dangerouslySetInnerHTML={{ __html:
          `const gpcValue = navigator.globalPrivacyControl

            if (gpcValue) {

            // signal detected, do something
 
           }`
          }}/>

        <Header
          links={[
            { title: 'Fountain', url: '/fountain',onClick: (e) => this.handleNavClick(e, '.fountain.section','/fountain')},
            { title: config.site.blurb.navTitle, url: '', onClick: (e) => this.handleNavClick(e, '.blurb.section','')},
            { title: config.site.products.navTitle, url: '', onClick: (e) => this.handleNavClick(e, '.products.section','') }
          ]}
          logo={config.site.logo}
        />
        
        {this.props.children}
        
        <Footer
          copyright={config.site.legal}
          links={[
            { title: 'Privacy Policy', url: 'https://www.keurig.com/content/privacy-policy?showContentOnly=1',onClick: (e) => this.handleFooterClickOutbound(e, 'Privacy Policy','https://www.keurig.com/content/privacy-policy?showContentOnly=1')},
            { title: 'Do Not Share Or Sell My Personal Information ', url: '#', onClick: this.scriptLoaded},
            { title: 'CA Notice at Collection', url: 'https://www.keurig.com/california-privacy-rights',onClick: (e) => this.handleFooterClickOutbound(e, 'CA Privacy Policy','https://www.keurig.com/content/privacy-policy#heading_6') },
            { title: 'Terms of Use', url: '#', onClick: this.handleTermsClick },
            { title: 'Accessibility Statement', url: 'https://www.drpeppersnapplegroup.com/accessibility',onClick: (e) => this.handleFooterClickOutbound(e, 'Accessibility Statement','https://www.drpeppersnapplegroup.com/accessibility') },
            { title: 'Careers', url: 'https://careers.keurigdrpepper.com/',onClick: (e) => this.handleFooterClickOutbound(e, 'Careers','https://careers.keurigdrpepper.com/') },
            { title: 'Let\'s Play', url: 'https://www.letsplay.com/',onClick: (e) => this.handleFooterClickOutbound(e, 'Let\'s Play','https://www.letsplay.com/')},
            { title: 'Contact', url: 'https://www.drpeppersnapplegroup.com/contact',onClick: (e) => this.handleFooterClickOutbound(e, 'Contact','https://www.drpeppersnapplegroup.com/contact')},
          ]}
          logo={
            <a href="/" className="footer__logo-link" onClick={this.handleFooterLogoClick}>
              <img
                className="footer__logo"
                src="/images/logo.png"
                alt="Schweppes Logo"
              />
            </a>
          }
        />
        <Modal
          id="terms-modal"
          active={this.state.termsActive}
          onClose={() => this.handleTermsClose()}
        >
          <TermsOfService />
        </Modal>
      </div>
    );
  }
}

Layout.defaultProps = {
  head: {
    favicon: '',
    openGraph: {
      description: '',
      title: '',
      siteName: ''
    },
    metadata: {
      title: '',
      description: ''
    }
  }
};

export default Layout;
